import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logout, setLoader, verifyUser } from '../../Redux/Actions/action.users';
import { verifyDuplicateURL  } from '../../Redux/Actions/action.document';
import { useNavigate } from 'react-router-dom';
import Modal from '../global/Modal/Modal';
import { signOut } from 'firebase/auth';
import { auth } from '../firebaseConfig';


function WebPageIngestion({toast,setLoader,logout,verifyUser,categoriesOptions,verifyDuplicateURL}) {
  const userInfo = localStorage.getItem('user')?JSON.parse(localStorage.getItem('user')):'';
  const navigate = useNavigate();
  const [errorsMetadataKeywords,setErrorsMetaDataKeywords] = useState({});
  const [params,setParams] = useState({
    url:'',
    maxdepth: 1,
    metadata:'',
    categoryIds: [],
    keywords:''
  });

  const[disabled, setdisable] = useState(false);

  const [categoryOptionsArray, setCategoryOptionsArray] = useState(categoriesOptions);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedCategoriesIds,setSelectedCategoriesIds] = useState([]);
  const [searchCategoriesInput,setSearchCategoriesInput] = useState('');
  const [showOptions, setShowOptions] = useState(false);
  const categoriesSelectRef = useRef(null);
  const optionsRef = useRef(null);

  const [showModal,setShowModal] = useState(false);
  const [existingURLDetails,setExistingURLDetails] = useState({
    url:'',
    depth:''
  })

  useEffect(()=>{
    setCategoryOptionsArray(categoriesOptions);
  },[categoriesOptions]);

  const handleDropdown = () => {
    setShowOptions(prev=>!prev);
  }

  const handleCategoryOptionSelect = (category) => {
    if(selectedCategories && selectedCategories.find(el=>el == category)) return;
    setSelectedCategories(prev=>([...prev,category]));
    setSelectedCategoriesIds(prev=>([...prev,category.id]));
    setParams(prev=>({
      ...prev,
      categoryIds:[...prev.categoryIds,category.id]
    }))
  }

  const handleSearchCategory = (searchterm) => {
    setCategoryOptionsArray(categoriesOptions.filter(el=> el.name.toLowerCase().includes(searchterm.toLowerCase())))
  }

  const removeSelectedCategory = (categoryId) => {
    setSelectedCategories(prev=>([...prev.filter(el=>el.id!==categoryId)]));
    setSelectedCategoriesIds(prev=>([...prev.filter(el=>el!==categoryId)]))
    setParams(prev=>({
      ...prev,
      categoryIds:[...prev.categoryIds.filter(el=>el!==categoryId)]
    }));
  }


  const handleChange = (e) => {
    const regex = /[^a-zA-Z0-9, ]/g;
    let {name,value} = e.target
    if(name == 'maxdepth'){
      value = parseInt(value);
    }
    if(name=='metadata' || name=='keywords'){
      if (value && regex.test(value)) { 
        setErrorsMetaDataKeywords(prev=>({
          [name]:`Special characters are not allowed in ${name}`
        }))
        return
      }
    }
    setParams(prev=>({ ...prev, [name]:value }));
  }

  const verifyUserToken = async()=>{
    let userVerified;
 
    await verifyUser(userInfo.user.stsTokenManager.accessToken).then(res=>{
      userVerified = true
    }).catch(error=>{
      if(error?.response?.status == 401){
        userVerified = false;
        signOut(auth).then(res=>{
          navigate('/');
        }).catch(error=>console.log(error))
      }
    });
    return userVerified;
}

  const checkDuplicateUrl = async() => {
    const valid = await validateParams('all');
    if(!valid) return;
    let ingestionVerified;
    await verifyDuplicateURL(params.url).then(res=>{
      if(res?.status == 'logout'){
        signOut(auth).then(res=>{
          navigate('/');
        }).catch(error=>console.log(error))
      }
      console.log(res?.data?.length)
      if(res?.status=='success' && res?.data?.length>0){
        ingestionVerified = false;
        setExistingURLDetails({ url:params.url, depth:`[ ${res.data.join(',')} ]` });
        setShowModal(true);
      }else{
        ingestionVerified = true
      }
      return
    }).catch(error=>{
      console.log(error);
    });
    if(ingestionVerified) ingestWebFiles(); 
      
  }

  const ingestWebFiles = async () => {
    closeModal();
    const res = await verifyUserToken();
    if(!res) return;
    
    setErrorsMetaDataKeywords({});
    // request JSON body
    const headers = { 
      // auth header with bearer token
      'Authorization': `Bearer ${userInfo.user.stsTokenManager.accessToken}`,
      'Accept': '*/*',
      "Access-Control-Allow-Origin": "*",
    }; 
    setLoader('webIngestionApi',true);
    await axios.post(`${process.env.REACT_APP_PYTHON_API_URL}/web-load`, params, { headers })
      .then(response => {
        setLoader('webIngestionApi',false);
        toast('success','File ingested successfully');
        setParams({
          url:'',
          maxdepth: 1,
          categoryIds: selectedCategoriesIds,
          metadata:'',
          keywords:'',
          categoryIds:[]
        });
        setSelectedCategories([]);
        setSelectedCategoriesIds([]);
      }).catch(error=>{
        console.log(error);
        setLoader('webIngestionApi',false);
        setTimeout(()=>{
          setParams({
            url:'',
            maxdepth: 1,
            metadata:'',
            keywords:'',
            categoryIds:[]
          });
          setSelectedCategories([]);
          setSelectedCategoriesIds([]);
        },500)
        if(error && error?.response?.status==401){
          signOut(auth).then(res=>{
            navigate('/');
          }).catch(error=>console.log(error))
          // toast('error','Session Expired');
          return
      }
      if(error && error?.response?.status == 400){
        toast('error',error?.response?.data?.message);
        return
      }
      toast('error','Error Ingesting page')
      console.log(error);
      });
  }

  const validateParams = (parameter) => {
    let errors={};
    if(parameter == 'all' || parameter == 'url'){
      if(!params.url){ errors.url = 'Please enter a URL.' }
      if(params.url){
        if(!params.url.includes('.')){errors.url = 'Please enter a valid URL ( eg. https://www.example.com )'}
        if(params.url.includes('www.')){
          setParams(prev=>({ ...prev, url:`https://www.${params.url.split('www.')[1]}` }));   // --------- UPDATE URL TO HTTPS IF NOT HTTPS ------- //
        }
        if(!params.url.includes('www.')){
          if(params.url.includes('https://')){setParams(prev=>({ ...prev, url:`https://${params.url.replace('https://','')}` }))}else
          if(params.url.includes('http://')){setParams(prev=>({ ...prev, url:`https://${params.url.replace('http://','')}` }))}else
          if(!params.url.includes('http://')){setParams(prev=>({ ...prev, url:`https://${params.url}` }))}
        }
      }
    }
    if(!params.maxdepth){ errors.depth = 'Please select a depth.' }
    if(parameter == 'all' || parameter == 'metadata' || parameter == 'keywords'){
      if(!params.metadata){ errors.metadata = 'Please enter metadata.' }
      if(params.metadata && params.metadata.length>1024){ errors.metadata = 'Exceeded character limit of 1024 characters' }
      if(!params.keywords){ errors.keywords = 'Please enter keywords' }
      if(params.keywords && params.keywords.length>1024){ errors.keywords = 'Exceeded character limit of 1024 characters' }
    }
    if(parameter == 'all'){
      if(selectedCategoriesIds.length==0){
        errors.categories = 'Please select at least 1 category'
      }
    }

    if(Object.keys(errors).length){
      setErrorsMetaDataKeywords(errors);
      return false
    }else{
      return true;
    }
  }

  useEffect(()=>{
    const wrapper = document.getElementById('document-ingestion-container-wrapper');
    wrapper.addEventListener('click',handleOutsideClick);
      return ()=>{
        wrapper.removeEventListener('click',handleOutsideClick);
      }

  },[])
  const handleOutsideClick = (e) => {
    if(optionsRef.current && !optionsRef.current.contains(e.target) && e.target.id!='dropdown-arrow' && e.target.id!='search-category-input' ){
      setShowOptions(false);
      clearSearchFilter();
    }
  }

  const clearSearchFilter = () => {
    setSearchCategoriesInput('');
    setCategoryOptionsArray(categoriesOptions);
  }

  //----- CLEAR ALL WEB INGESTION INPUTS -----
  // const clearInputs = () => {
  //   setParams({
  //     url:'',
  //     maxdepth: 1,
  //     metadata:'',
  //     categoryIds: [],
  //     keywords:''
  //   });
  //   setSelectedCategories([]);
  // }


  const closeModal = () => {
    // ---- CLOSE THE WEBPAGE EXISTING VALIDATION MODAL -----
    setShowModal(false);
    setExistingURLDetails({ url:'', depth:'' });
  }

  return (
    <div className='document-container mx-0 mx-md-4 max-w-720'>
      <div className='document-title py-2'>
          <div className='document-heading'>Web Page Ingestion</div>
      </div>
      <div className="d-flex flex-column align-items-start pt-3">
        <label className='keywords-input-label' htmlFor='url'>URL:<span className={`validation-error`}>{Object.keys(errorsMetadataKeywords).length>0?errorsMetadataKeywords.url:''}</span></label>
        <input type="text" name="url" id='url' className='mb-3 form-control' value={params.url} placeholder='Enter url' onChange={handleChange} onBlur={()=>{validateParams('url')}} />
        <label className='keywords-input-label' htmlFor='depth'>Max Depth:<span className='info-icon' title='The depth of web site URLs to scrape. Up to provided depth sub-URLs will be scrapped during web ingestion. '><i class="fas fa-info-circle small"></i></span><span className={`validation-error`}>{Object.keys(errorsMetadataKeywords).length>0?errorsMetadataKeywords.depth:''}</span></label>
        <select className='form-select mb-3 w-auto' name='maxdepth' onChange={handleChange} value={params.maxdepth} placeholder='Select maximum depth' >
          {[1,2,3,4,5].map((depth)=>{
            return <option key={depth} value={depth}>{depth}</option>
          })}
        </select>
        
        
        <label className='keywords-input-label' htmlFor='categories'>Categories:<span className='info-icon' title='Category of webpage. Different type of categories can help to segregate documents.'><i class="fas fa-info-circle small"></i></span><span className={`validation-error`}>{Object.keys(errorsMetadataKeywords).length>0?errorsMetadataKeywords.categories:''}</span></label>
        <div className='categories-base-dropdown-container mx-0 w-100 mb-3'>
        <div className='categories-dropdown' id='categories'>
            <div className={`categories-dropdown-container `} ref={categoriesSelectRef}>
                <div className='input-field'>
                    <div className='search-category-container'>
                        <input type='text' className={`search-category-name`} placeholder='Select Categrories' id='search-category-input' value={searchCategoriesInput}  onFocus={()=>{setShowOptions(true)}} onChange={(e) => { handleSearchCategory(e.target.value);setSearchCategoriesInput(e.target.value) }}/>
                        <i className="fa-solid fa-chevron-down cursor-pointer" onClick={() => { handleDropdown();clearSearchFilter() }} id='dropdown-arrow'></i>
                    </div>
                    <div className='selected-categories-container'>
                        {selectedCategories && selectedCategories.map(category => {
                            return <span key={category.id} className='selected-category py-1'>{category.name} <i className="fa-solid fa-xmark px-1 small" onClick={() => { removeSelectedCategory(category.id) }}></i></span>
                        })}
                    </div>
                </div>
                <div className={`options-container options-position-absolute options-container-width ${ showOptions ? '' : 'd-none'}` } ref={optionsRef}>
                    {categoryOptionsArray.length>0?categoryOptionsArray.map(category => {
                        return <li key={category.id} className={`category-option ${selectedCategories && selectedCategories.find(el => el.id == category.id) ? 'selected' : ''}`}
                              onClick={() => { handleCategoryOptionSelect(category) }}>{category.name}</li>
                    }):
                    <div className={``}>No Options Available</div>
                    }
                </div>
            </div>
        </div>
      </div>
      <label className='keywords-input-label' htmlFor='metadata'>Meta Data:<span className='info-icon' title='Meta data of webpage. This will help the customer to search webpage for sync process.'><i class="fas fa-info-circle small"></i></span><span className={`validation-error`}>{Object.keys(errorsMetadataKeywords).length>0?errorsMetadataKeywords.metadata:''}</span></label>
        <textarea name="metadata" id='metadata' className='metadata-input mb-3 form-control' value={params.metadata} placeholder='Enter Meta Data' onChange={handleChange} ></textarea>
        <label className='keywords-input-label' htmlFor='keyword'>Keywords:<span className='info-icon' title='Keywords of webpage. This will help the customer to search webpage for sync process.'><i class="fas fa-info-circle small"></i></span><span className={`validation-error`}>{Object.keys(errorsMetadataKeywords).length>0?errorsMetadataKeywords.keywords:''}</span></label>
        <textarea name="keywords" id='keyword' className='form-control mb-3' value={params.keywords} placeholder='Enter Keywords' onChange={handleChange}></textarea>
        <button className='btn btn-primary ingest-button mt-2 w-auto' disabled={disabled} onClick={checkDuplicateUrl}>Ingest</button>
      </div>
      <Modal showModal={showModal} closeModal={closeModal} title={'Web Ingestion'}>
        <div className='d-flex'>
          <p>URL <strong className='text-bold'>{existingURLDetails.url}</strong> with depths  <strong className='text-bold'>{existingURLDetails.depth}</strong> is already ingested. Do you still want to ingest?</p>
        </div>
        <footer className='mt-4'>
          <button className='btn btn-dark mx-2 px-4 py-1' onClick={()=>{ingestWebFiles()}}>Yes</button>
          <button className='btn btn-light mx-2 px-4 py-1' onClick={()=>closeModal()}>No</button>
        </footer>
      </Modal>
    </div>
  )
}

const mapStateToProps = (state) =>{
  return {
    categoriesOptions: state.categories.categoriesOptions
  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    setLoader,
    logout,
    verifyUser,
    verifyDuplicateURL
  },dispatch)
}
export default connect(mapStateToProps,mapDispatchToProps)(WebPageIngestion)