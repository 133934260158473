export const USER_LOGIN = "USER_LOGIN";
// export const USER_REGISTRATION = "USER_REGISTRATION";
export const ADD_LOADING = "ADD_LOADING";
export const REMOVE_LOADING = "REMOVE_LOADING";
export const DOCUMENTS_LIST = "DOCUMENTS_LIST";
export const SEARCH_OPTIONS = "SEARCH_OPTIONS";
export const USER_LOGOUT = "USER_LOGOUT";

export const ADD_TOAST ="ADD_TOAST";
export const REMOVE_TOAST = "REMOVE_TOAST";
export const SET_CATEGORIES_OPTIONS = "SET_CATEGORIES_OPTIONS";
export const SET_CUSTOMER_LIST = "SET_CUSTOMER_LIST";

export const SET_CATEGORIES_OPTIONS_PAGINATION = "SET_CATEGORIES_OPTIONS_PAGINATION"