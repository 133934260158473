import { ADD_LOADING,REMOVE_LOADING } from "../Actions"

const initialState = {
    loading:[],
}

let loadingArray;
export const loadingState = (state=initialState,action)=>{
    switch(action.type){
        case ADD_LOADING:
            return {
                ...state,
                loading:[...state.loading,action.payload]
            };
        case REMOVE_LOADING:
            loadingArray = state.loading.filter(el=> el!==action.payload);
            return{
                ...state,
                loading:loadingArray
            }
        default: return state
    }
}