import {SET_CATEGORIES_OPTIONS,SET_CATEGORIES_OPTIONS_PAGINATION} from '../Actions'

const initialState = {
    categoriesOptions:[],
    categoriesList:[],
    pageInfo:{},
}
export const categories = (state=initialState,action)=>{
    switch(action.type){
        case SET_CATEGORIES_OPTIONS:
            return {
                ...state,
                categoriesOptions:action.payload,
            }
        case SET_CATEGORIES_OPTIONS_PAGINATION:
            return {
                ...state,
                categoriesList:action.payload.categoriesList,
                pageInfo:action.payload.pageInfo 
            }
        default: return state
    }
}