import React, { useState,useEffect } from 'react'
import AddEdit from './AddEdit';
import CustomerManagementTable from './CustomerManagementTable';
import Modal from '../global/Modal/Modal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getAllCustomers } from '../../Redux/Actions/action.customers';

function CutomerManagement({toast, getAllCustomers, customerList, pageInfo, categoriesOptions}) {
    const [displayModal,setDisplayModal] = useState(false);
    const [modalTitle,setModalTitle] = useState('')
    const [editCustomer,setEditCustomer] = useState({
      id:'',
      name:'',
      key:'',
      status:'enabled',
      categories:[]
    });

    const [perPageEntries,setPerPageEntries] = useState(100);

    const [pageDetails,setPageDetails] = useState({
      page: 1,
      perPageSize: perPageEntries,
      sortColumn: 'name',
      sortType: 'desc',
    });

    const [pages,setPages] = useState({
      numberOfPages: [...Array(1)],
      pagesDisplayFrom: 1,
      pagesDisplayTo: 1
    });

    useEffect(()=>{
      const params = {
        page: pageDetails.page,
        size: pageDetails.perPageSize,
        sortColumn: pageDetails.sortColumn,
        sortType: pageDetails.sortType
      }
      getAllCustomers(params);
    },[pageDetails]);

    useEffect(()=>{
      if(pageInfo){
        const startPage = pageInfo.currentPage%5==0?pages.pagesDisplayFrom:Math.floor((pageInfo.currentPage)/5)*5 + 1;
        const endPage = pageInfo.totalPages > 5 ?  pageInfo.currentPage%5==0? pages.pagesDisplayTo : (Math.floor((pageInfo.currentPage)/5)*5 + 5 > pageInfo.totalPages ? pageInfo.totalPages : Math.floor((pageInfo.currentPage)/5)*5 + 5) : pageInfo.totalPages
        setPages(prev=>({
          ...prev,
          numberOfPages: [...Array(pageInfo.totalPages)],
          pagesDisplayFrom: startPage,
          pagesDisplayTo: endPage
        }))
      }
    },[pageInfo]);

    const changePagesDisplay = (type) => {
      if(type=='prev'){
        if(pages.pagesDisplayFrom == 1) return ;
          setPages(prev=>({
            ...prev,
            pagesDisplayFrom: pages.pagesDisplayFrom - 5,
            pagesDisplayTo: pages.pagesDisplayFrom - 1
          }))
      }
      if(type=='next'){
        if(pages.pagesDisplayTo == pages.numberOfPages.length) return ;
        setPages(prev=>({
          ...prev,
          pagesDisplayFrom: pages.pagesDisplayFrom + 5,
          pagesDisplayTo: pages.pagesDisplayTo + 5>pages.numberOfPages.length ? pages.numberOfPages.length : pages.pagesDisplayTo + 5
        }))
      }
    }

    const updatePageEntries = (value)=>{
      if( !value || value==0 || value>100) return;
      setPerPageEntries(value);
    }
  
    const updatePageDetails = (e)=>{
      const {name,value} = e.target
      if( !value || value==0 || value>100) return;
      setPageDetails((prev)=>({
        ...prev,
        [name]:value,
        page:1,
      }));
    }

    const navigatePage = (newPage) => {
      if(newPage == 0) return;
      if(newPage>pageInfo.totalPages) return;
      if(newPage > pageDetails.page && newPage<=pageInfo.totalPages && newPage>pages.pagesDisplayTo){
        changePagesDisplay('next');
      }
      if(newPage < pageDetails.page && newPage>1 && newPage<pages.pagesDisplayFrom){
        changePagesDisplay('prev');
      }
      setPageDetails((prev)=>({
        ...prev,
        page:newPage
      }))
    }

    const pageChange = (page) => {
      if(pageDetails.page == page) return
      setPageDetails((prev)=>({
        ...prev,
        page: page
      }));
    }

    const toggleModal = (title,customer) => {
      if(!customer){
        setEditCustomer({
          id:'',
          name:'',
          key:'',
          status:'Enable',
          categories:[],
          configuredCategories:[]
        })
      }else{
        setEditCustomer({
          id:customer.id,
          name:customer.name,
          key:customer.key,
          status:customer.status,
          categories: customer.configuredCategories.map(category=>category),
          configuredCategories: customer.configuredCategories.map(category=>category.id)
        });
      }
      setModalTitle(title);
      setDisplayModal(prev=>!prev)
    }
    const closeModal = () => {
      setDisplayModal(false);
    }
  return (
      <div className='document-container mx-4'>
        <div className='document-title py-2'>
          <div className='document-heading'>Customer Management</div>
          <div className='search-button ms-auto'>
            <button className='btn btn-primary' onClick={()=>{toggleModal('Add')}}>Add</button>
            <Modal title={`${modalTitle} Customer`} showModal={displayModal} closeModal={() => { closeModal() }}>
              <AddEdit closeModal={() => { closeModal() }} editCustomer={editCustomer} toast={toast} modalTitle={modalTitle} />
            </Modal>
          </div>
        </div>
        <CustomerManagementTable toast={toast} toggleModal={toggleModal} />
        <div className='document-footer pt-4'>
        <div className='page-numbers-section'>
          <div className={`previous-page px-1 mx-1 ${pages.pagesDisplayFrom==1?'disabled':''}`} onClick={()=>{changePagesDisplay('prev')}}><i className="fas fa-angle-double-left"></i></div>
          <div className={`previous px-1 ${pageDetails.page==1?'disabled':''}`} onClick={()=>{navigatePage(pageDetails.page-1)}} title='Previous Page'><i class="fas fa-angle-left"></i></div>
          <div className={`page-number ${pages.pagesDisplayFrom == 1?'d-none':'continuance-indicator'}`} disabled>...</div>
            {pages.numberOfPages.map((page,index)=>{
              return pages.pagesDisplayFrom-1<index+1 &&  pages.pagesDisplayTo > index && <div key={index+1} className={`page-number px-2 ${pageDetails.page == index+1?'active':''}`} onClick={()=>pageChange(index+1)}>{index + 1}</div>
              })}
            <div className={`page-number ${pages.pagesDisplayTo == pages.numberOfPages.length?'d-none':'continuance-indicator'}`} disabled>...</div>
            <div className={`next px-1 ${pageDetails.page==pages.numberOfPages.length?'disabled':''}`} onClick={()=>{navigatePage(pageDetails.page+1)}} title='Next Page'><i class="fas fa-angle-right"></i></div>
          <div className={`next-page px-1 mx-1 ${pages.pagesDisplayTo==pages.numberOfPages.length?'disabled':''}`} onClick={()=>{changePagesDisplay('next')}}><i className="fas fa-angle-double-right"></i></div>
        </div>
        <div className='page-number-details grow-1'>{`Showing `}
          {customerList && customerList?.length?(pageDetails.page-1)*pageDetails.perPageSize+1:0}{ ` to `} 
          {customerList && customerList?.length?(pageDetails.page*pageDetails.perPageSize)>(pageInfo?.totalRecords)?(pageInfo?.totalRecords):(pageDetails.page*pageDetails.perPageSize):0}{` of `}
          {Object.keys(pageInfo).length?(pageInfo?.totalRecords):0} entries</div>
        <div className='number-of-pages'>
          Show <input type='text' className='entries-per-page px-1' value={perPageEntries} name='perPageSize' onBlur={(e)=>{updatePageDetails(e)}} onChange={(e)=>{updatePageEntries(e.target.value)}}/>
          entries
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    customerList: state.customers.customerList,
    pageInfo: state.customers.pageInfo,
    categoriesOptions: state.categories.categoriesOptions
  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getAllCustomers,
  },dispatch)
}

export default connect(mapStateToProps,mapDispatchToProps)(CutomerManagement)