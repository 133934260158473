import React, { useState, Children, useRef } from 'react'
import './Modal.css';

function Modal({showModal,title,closeModal,children,footer}) {
  const modalRef = useRef(null);
  const handleOutsideClick = (e)=>{
    if(modalRef.current && !modalRef.current.contains(e.target)){
        // closeModal()
    }
  }
  return (
    <div className={`${showModal?'':'d-none'} modal-wrapper`} id='modal-wrapper' onClick={(e)=>handleOutsideClick(e)}>
        <div className='modal-container' ref={modalRef}>
            {/* MODAL CONTAINER */}
            <div className='modal-title'>
                <h3>{title}</h3>
                <div onClick={()=>{closeModal()}}><i className="fa-solid fa-xmark"></i></div>
            </div>
            <div className='modal-body'>{children}</div>
            <div className='modal-footer'>{footer}</div>
        </div>
    </div>
  )
}

export default Modal