import { USER_LOGIN,USER_REGISTRATION,USER_LOGOUT } from "./../Actions"

const initialState = {
    user:{},
}

export const userReducer = (state=initialState,action)=>{
    switch(action.type){
        case USER_LOGIN:
            return {
                ...state,
                user:action.payload
            };
        case USER_LOGOUT:
            return {
                ...state,
                user:{}
            };
        // case USER_REGISTRATION:
        //     return{
        //         ...state,
        //         // user:action.payload
        //     }
        default: return state
    }
}