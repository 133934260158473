// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-wrapper{
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(0,0,0,0.6);
    height: 100%;
    width: 100%;
    display:flex;
    align-items: center;
    justify-content: center;
}

.modal-container{
    border-radius: 3px;
    background-color: #e9e9e9;
    width: 30%;
    max-width: 90%;
    min-height: 20%;
    max-height: 90%;
    overflow:scroll;
    padding: 1%;
}

.modal-container::-webkit-scrollbar{
    display: none;
}

.modal-title{
    display: flex;
    justify-content: space-between;
}

.modal-title h3{
    font-weight: 600;
}

.modal-container {
    border-radius: 8px !important;
    background-color: #fff !important;
  }
  .modal-title div {
    cursor: pointer;
  }`, "",{"version":3,"sources":["webpack://./src/components/global/Modal/Modal.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,OAAO;IACP,MAAM;IACN,2BAA2B;IAC3B,YAAY;IACZ,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,UAAU;IACV,cAAc;IACd,eAAe;IACf,eAAe;IACf,eAAe;IACf,WAAW;AACf;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,6BAA6B;IAC7B,iCAAiC;EACnC;EACA;IACE,eAAe;EACjB","sourcesContent":[".modal-wrapper{\n    position: fixed;\n    left: 0;\n    top: 0;\n    background: rgba(0,0,0,0.6);\n    height: 100%;\n    width: 100%;\n    display:flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.modal-container{\n    border-radius: 3px;\n    background-color: #e9e9e9;\n    width: 30%;\n    max-width: 90%;\n    min-height: 20%;\n    max-height: 90%;\n    overflow:scroll;\n    padding: 1%;\n}\n\n.modal-container::-webkit-scrollbar{\n    display: none;\n}\n\n.modal-title{\n    display: flex;\n    justify-content: space-between;\n}\n\n.modal-title h3{\n    font-weight: 600;\n}\n\n.modal-container {\n    border-radius: 8px !important;\n    background-color: #fff !important;\n  }\n  .modal-title div {\n    cursor: pointer;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
