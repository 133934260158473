import React from 'react'

function CategoryTable({data,deleteCategory,toggleModal}) {

  return (
    <div className='document-table w-100'>
      <div className='table-section'>
        <table className='table-properties table-bordered'>
          <thead>
            <tr className='table-headers'>
                <th className='py-3 px-2'>Name</th>
                <th className='py-3 px-2'>Description</th>
                <th className='py-3 px-2'>Edit / Delete</th>
            </tr>
          </thead>
          <tbody>
            {data.length>0 && data.map(category=>{
              return <tr className='table-body-data'>
                <td className='py-2 px-2'>{category.name}</td>
                <td className='py-2 px-2'>
                  <div className=''>{category.description}</div>
                </td>
                <td className='w-150px text-center'>
                  <span onClick={()=>{toggleModal('Edit',category)}}><img src='./images/pencil-alt-solid.svg' className='mx-2 edit' alt='edit' /></span>
                  <span onClick={()=>{deleteCategory(category)}}><img src='./images/trash-alt-solid.svg' className='mx-2 delete' alt='delete' /></span>
                </td>
              </tr>

            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default CategoryTable