import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getAllFiles } from '../../Redux/Actions/action.document';
import { useNavigate } from 'react-router-dom';
import { logout, setLoader } from '../../Redux/Actions/action.users';
import axios from 'axios';
import { signOut } from 'firebase/auth';
import { auth } from '../firebaseConfig';

function DataTable({ documents, pageInfo, getAllFiles, setLoader, toast, logout}) {
  const tableHeaders = ['Source', 'Size', 'Depth', 'Categories', 'Meta Data', 'Keywords', 'Date/Time'];
  const [documentList, setDocumentList] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    if(documents){
      let updatedList = documents;
      updatedList = updatedList.map((file) => {
        return {
          ...file,
          filesize: file?.filesize?.split(' (')[0],
          categories: file?.categories?.map(category=>category.name).join(', ')
        }
      });
      setDocumentList(updatedList);
    }
  }, [documents]);

  const sortArray = async()=>{
    const params ={
      page: pageInfo.page,
      perPageSize: pageInfo.perPageSize,
      sortType: pageInfo.sortType=='desc'?'asc':'desc',
      searchString : pageInfo.searchString,
      searchCategory: pageInfo.searchCategory
    }
    getAllFiles(params).then(res=>{
      if(res && res.status=='logout'){
        signOut(auth).then(res=>{
          navigate('/');
        }).catch(error=>console.log(error))
      }
    })
  }

  const handleFileUrl = async(filename,url) => {
    setLoader('openFile',true);
    //---------------- GET EXTENSION FROM FILENAME --------------------------
    
    const filenameArray = filename.split('.');
    const extension = filenameArray.length?filenameArray[filenameArray.length-1]:'';

    if(extension.toLowerCase() == 'csv'){
      window.open(url);
      setLoader('openFile',false);
      return;
    }

    if(extension.toLowerCase() == 'pdf'){
      //------------- GET FILE FROM MEDIA URL --------------------------------------      
      const docRef = {
        method:'GET',
        url:url,
        responseType: 'blob',
      }
      const pdfWindow = window.open();
      pdfWindow.document.title = 'Loading...'
      await axios(docRef).then(doc=>{
        if(doc.status==200){
          const file = new File([doc.data],filename,{type:doc.data.type});
          const fileURL = URL.createObjectURL(file);
          
          //------------- OPEN THE URL ON NEW WINDOW -------------------------------
          pdfWindow.location.href = fileURL;
          setLoader('openFile',false);
          return;
        }
      }).catch(error=>{
        pdfWindow.close();
        toast('error','Error fetching file');
        setLoader('openFile',false);
      });
      return;
    }
    window.open(filename);
    setLoader('openFile',false);

  }

  return (
    <div className='document-table'>
      <div className='table-section'>
        <table className='table-properties table-bordered'>
          <thead>
            <tr className='table-headers'>
              {tableHeaders.map((header,index) => {
                return <th className='py-3 px-2 ' key={index}>{header} {header == 'Date/Time' && <i className="fas fa-exchange-alt sort-icon" onClick={()=>{sortArray()}}></i>}</th>
              })}
            </tr>
          </thead>
          <tbody>
            {
              documentList.map((data, index) => {
                return <tr className='table-body-data' key={index}>
                  <td className='py-2 px-2' ><p className='d-inline-block mb-0 file-link text-nowrap' onClick={()=>{handleFileUrl(data.fileName,data.refUrl)}}>{data.fileName}</p></td>
                  <td className='py-2 px-2 min-w-130'>{data.filesize}</td>
                  <td className='py-2 px-2 text-center'>{data.depth}</td>
                  <td className='py-2 px-2'>{data.categories}</td>
                  <td className='py-2 px-2'>{data.metaData}</td>
                  <td className='py-2 px-2'>{data.keywords}</td>
                  <td className='py-2 px-2 min-w-150'>{moment(data.ingestionDatetime).format('MM/DD/YYYY HH:mm')} </td>
                </tr>
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    documents: state.documents.documentList,
    pageInfo: state.documents.pageInfo,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    setLoader,
    getAllFiles,
    logout
  },dispatch)
}

export default connect(mapStateToProps,mapDispatchToProps)(DataTable)