import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { logout, verifyUser } from '../../Redux/Actions/action.users';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../firebaseConfig';
const BASE_API_URL = process.env.REACT_APP_API_BASE_URL;

function DocumentIngestion({ users, toast, logout, verifyUser, categoriesOptions }) {
    const [fileList, setFileList] = useState([]);
    const [fileExtraData, setFileExtraData] = useState({});
    const [fileProgress, setFileProgress] = useState({})
    const [accordionOpen, setAccordionOpen] = useState('');
    const userInfo = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : '';
    const [error, setError] = useState(false);
    const [ingestDisabled, setIngestDisabled] = useState(true);
    const [filesProcessed, setFilesProcessed] = useState([]);
    const [errorsMetadataKeywords, setErrorsMetaDataKeywords] = useState({});
    const [fileError, setFileError] = useState('');
    const navigate = useNavigate();


    useEffect(()=>{
        const wrapper = document.getElementById('document-ingestion-container-wrapper');
        wrapper.addEventListener('click',handleOutsideClick);
        return ()=>{
         wrapper.removeEventListener('click',handleOutsideClick);
        }
    },[])

    const addFiles = async (transferFiles) => {
        setIngestDisabled(false);
        setFileError('');
        let filesToPush = await fileList.filter(file => {
            if (!filesProcessed.includes(file.index)) {
                return file;
            }
        });
        let files = [...transferFiles];
        let filterExtraDataObject = fileExtraData;
        let progress = { ...fileProgress };
        files.map(file => {
            let fileName = file.name;
            let splitName = fileName.split('.');
            file.ext = splitName[splitName.length - 1].toLowerCase()
            if (!['application/pdf', 'text/csv'].includes(file.type.toLowerCase())) {
                setFileError('Not a supported format. Accepted formats are CSV & PDF');
                return;
            }
            if (file.size > 30 * 1024 * 1024) {
                setFileError('The file may not be greater than 30 MB.');
                return;
            }
            if (file) {
                file.uploadStatus = '';
                file.statusMessage = '';
                file.index = btoa(Date.now() + file.name + (Math.floor(100000 + Math.random() * 900000)));
                file.shortName = file.ext.toUpperCase() + ' Ingestion - ' + file.name;
                if (fileName.length >= 12) {
                    fileName = splitName[0].substring(0, 13) + "... ." + splitName[1];
                    file.shortName = file.ext.toUpperCase() + ' Ingestion - ' + splitName[0].substring(0, 13) + "... ." + splitName[1];
                }
            }
            filesToPush.push(file);
            progress = {
                ...progress,
                [file.index]: 0
            };
        });
        setAccordionOpen('');
        setFileList(filesToPush);
        filesProcessed.map(fileIndex => {
            delete filterExtraDataObject[fileIndex];
            delete progress[fileIndex];
        });
        setFileExtraData(filterExtraDataObject);
        setFileProgress(progress);
        setFilesProcessed([]);

        //---------------------REMOVE EARLIER REFERENCES TO FILES TO ALLOW UPLOAD SAME FILE------------------------//
        const inputbox = document.getElementById('input-file');
        inputbox.value = '';
    }

    const toggleAccordion = (fileIndex) => {
        setAccordionOpen(fileIndex);
        if (fileIndex == '' || fileIndex != showOptions) 
            {
                setShowOptions('');
                clearSearchFilter();
            }
    }

    const verifyUserToken = async () => {
        let userVerified;
        await verifyUser(userInfo?.user?.stsTokenManager?.accessToken).then(res => {
            userVerified = true
        }).catch(error => {
            if (error && error?.response?.status == 401) {
                userVerified = false
                signOut(auth).then(res=>{
                    navigate('/');
                  }).catch(error=>console.log(error))
            }
        });
        return userVerified;
    }

    const handleChange = (e) => {
        e.preventDefault();
        addFiles(e.target.files);
    }

    const handleDragnDrop = (e) => {
        e.preventDefault();
        addFiles(e.dataTransfer.files)
    }

    const handleDataChange = (e, fileIndex) => {
        const { name, value } = e.target;
        const regex = /[^a-zA-Z0-9, ]/g;
        if (name == 'metadata' || name == 'keywords') {
            if (value && regex.test(value)) {
                setErrorsMetaDataKeywords(prev => ({
                    [fileIndex]: {
                        [name]: `Special characters are not allowed in ${name}`
                    }
                }))
                return;
            }
            if (value && value.length > 1024) {
                setErrorsMetaDataKeywords(prev => ({
                    [fileIndex]: {
                        [name]: `Exceeded character limit of 1024 cahracters in ${name}`
                    }
                }))
                return;
            }
        }

        setFileExtraData(prev => ({
            ...prev,
            [fileIndex]: {
                ...fileExtraData[fileIndex],
                [name]: value
            }
        }));
    }

    const uploadFiles = async () => {
        const res = await verifyUserToken();
        if (!res) return

        const uploadFilesList = fileList;
        const noMetaData = uploadFilesList.filter(file => {
            const metaData = fileExtraData[file.index] && fileExtraData[file.index]?.metadata?.split(',').filter(el=>el!='').length!=0;
            const keywords = fileExtraData[file.index] && fileExtraData[file.index]?.keywords?.split(',').filter(el=>el!='').length!=0;
            const categories = selectedCategoryIds[file.index]?.length > 0 && selectedCategoryIds[file.index];
            if (!metaData || !keywords || !categories) {
                return file;
            }
        });
        if (noMetaData.length) {
            toast('error', `${noMetaData.length} files has Categories, metaData or Keywords missing.`)
            return;
        }

        setIngestDisabled(true);
        setErrorsMetaDataKeywords({});
        console.log('File Ingestion Started')
        for (let file of uploadFilesList) {
            setFilesProcessed((prev) => ([...prev, file.index]));
            const formData = new FormData();
            const metaData = fileExtraData[file.index] && fileExtraData[file.index]?.metadata?.split(',');
            const keywords = fileExtraData[file.index] && fileExtraData[file.index]?.keywords?.split(',');
            const categories = selectedCategoryIds[file.index] && selectedCategoryIds[file.index]?.length > 0 && selectedCategoryIds[file.index];
            formData.append('files', file);

            formData.append('metadata', !metaData ? '' : metaData);
            formData.append('keywords', !keywords ? '' : keywords);
            formData.append('categoryIds', !categories ? '' : categories);
            const url = `${BASE_API_URL}/${file.ext}/load`;
            axios.post(url, formData, {
                headers: {
                    'Authorization': `Bearer ${userInfo.user.stsTokenManager.accessToken}`,
                    'Content-Type': 'multipart/form-data',
                    'Accept': '*/*',
                    "Access-Control-Allow-Origin": "*",
                },
                // crossDomain: true,
                onUploadProgress: (data) => {
                    let { loaded, total } = data;
                    setFileProgress(prev => ({
                        ...prev,
                        [file.index]: (loaded / total * 100).toFixed(0) - 3
                    }));
                }
            }).then(async res => {
                if (res.data.statusCode == 500) {
                    toast('error', 'Internal Server Error')
                }
                if (res.data.statusCode == 200) {
                    res.data.data.map((response) => {
                        const files = fileList.filter(file => file.name !== response.fileName)
                        let fileChanged = fileList.find(file => file.name == response.fileName);
                        fileChanged.uploadStatus = response.status
                        fileChanged.statusMessage = response.message
                        if (response.status == 'Success') {
                            setFileProgress(prev => ({
                                ...prev,
                                [file.index]: 100
                            }));
                        } else {
                            setFileProgress(prev => ({
                                ...prev,
                                [file.index]: 0
                            }));
                        }
                        setFileList((prev) => ([...files, fileChanged]));
                        if (response.status == 'Failed') {
                            setFileProgress(prev => ({
                                ...prev,
                                [file.index]: 0
                            }));
                        }
                    });
                }
            }).catch(error => {
                setError(true);
                console.log(error);

                if (error && error?.response?.status == 401) {
                    signOut(auth).then(res=>{
                        navigate('/');
                      }).catch(error=>console.log(error))
                    // toast('error','Session Expired');
                    return
                }
                toast('error', `Error uploading files ${file.name}`);
                setFileProgress(prev => ({
                    ...prev,
                    [file.index]: 0
                }));
                return;
            });
        }
    }

    const removeFromList = (fileIndex) => {
        const remainingFiles = fileList.filter(file => file.index !== fileIndex);
        setFileList(remainingFiles);
        remainingFiles.length == 0 && setIngestDisabled(true);

        let extraFiles = Object.keys(fileExtraData).filter((key) => !key.includes(fileIndex))
            .reduce((cur, key) => { return Object.assign(cur, { [key]: fileExtraData[key] }) }, {});

        setFileExtraData(extraFiles);

        Object.keys(fileProgress).forEach(key => {
            if (key == fileIndex) delete fileProgress[key];
        });
        setFileProgress(fileProgress);
    }

    const [categoriesOptionsArray, setCategoriesOptionsArray] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState({});
    const [selectedCategoryIds, setSelectedCategoryIds] = useState({});
    const [searchCategoryInput,setSearchCategoryInput] = useState({});
    const categoriesSelectRef = useRef(null);
    const optionsRef = useRef(null);
    const [showOptions, setShowOptions] = useState('');

    useEffect(() => {
        setCategoriesOptionsArray(categoriesOptions);
    }, [categoriesOptions])

    const handleSearchCategory = (searchterm) => {
        setCategoriesOptionsArray(categoriesOptions.filter(el => el.name.toLowerCase().includes(searchterm.toLowerCase())));
    }

    const removeSelectedCategory = (fileIndex, categoryId) => {
        setSelectedCategories(prev => ({ ...prev, [fileIndex]: prev[fileIndex].filter(el => el.id != categoryId) }));
        setSelectedCategoryIds(prev => ({ ...prev, [fileIndex]: prev[fileIndex].filter(el => el != categoryId) }));
    }

    const handleDropdown = (fileIndex) => {
        setShowOptions(prev => prev == fileIndex ? '' : fileIndex);
    }

    const handleCategoryOptionSelect = (fileIndex, category) => {
        if (selectedCategories[fileIndex] && selectedCategories[fileIndex].find(el => el == category)) return;
        setSelectedCategories(prev => ({
            ...prev,
            [fileIndex]: prev[fileIndex] ? [...prev[fileIndex], category] : [category]
        }));
        setSelectedCategoryIds(prev => ({
            ...prev,
            [fileIndex]: prev[fileIndex] ? [...prev[fileIndex], category.id] : [category.id]
        }));
    }

    const handleOutsideClick = (e) => {
        if(optionsRef.current && !optionsRef.current.contains(e.target) && e.target.id!='dropdown-arrow' && e.target.id!='search-category-input' ){
            setShowOptions('');
            clearSearchFilter();
          }
    }

    const clearSearchFilter = () => {
        setSearchCategoryInput({});
        setCategoriesOptionsArray(categoriesOptions);
    }


    return (
        <div className='document-container mx-0 mx-md-4 max-w-720'>
            <div className='document-title py-2'>
                <div className='document-heading'>Documents  Ingestion</div>
            </div>
            <div className="wrapper">
                <label htmlFor='input-file' className='py-2' role="button" id='drop-area' onDragOver={(e) => { e.preventDefault() }} onDrop={(e) => { handleDragnDrop(e) }}>
                    <div className='file-upload-icon'><img src='./images/upload_file_black.svg' className='' alt='upload' /></div>
                    <input type='file' accept='.csv,.pdf' id='input-file' hidden onChange={(e) => { handleChange(e) }} multiple />
                    <p className='upload-file-link mb-0 py-2'><span className='click-upload-link'>Click to upload</span> or drag and drop</p>
                </label>
                <div className='description-text'>
                    <p>Supported formats: CSV, PDF</p>
                    <p className={`input-validation-error validation-error`}>{fileError}</p>
                    <p>Maximum size: 30MB</p>
                </div>
                <section className="progress-area">
                    {fileList && fileList.map((file, index) => {
                        return <div className='complete-file-content mb-3 rounded' key={index}>
                            <div className="row list-element mx-0">
                                <div className='file-upload-status'>
                                    <p className={`${file.uploadStatus == '' ? 'd-none' : file.uploadStatus == 'Success' ? 'text-success' : 'text-danger'} my-1 file-upload-status`}>{file?.statusMessage}</p>
                                </div>
                                <div className="upload-file-content px-0">
                                    <div className="details">
                                        <div className='file-details'>
                                            <div className='file-background'>
                                                <span><i className={`fa-regular fa-file file-type ${file.ext}`}></i></span>
                                            </div>
                                            <div className='additional-file-details'>
                                                <span className="name file-name fw-bold text-truncate">{file.shortName} </span>
                                                <span className='file-size'>{file.size < 1000 * 1024 ? (file.size / (1024)).toFixed(2) + 'KB' : (file.size / (1024 * 1024)).toFixed(2) + 'MB'}</span>
                                            </div>
                                        </div>
                                        <span className='w-20px' onClick={() => { removeFromList(file.index) }}><i className="fa-solid fa-xmark cancel-upload-button"></i></span>
                                    </div>
                                    <div className='progress-details'>
                                        <div className={`${fileProgress[file.index] > 0 ? '' : 'd-none'} progress-bar`} style={{ 'background': `linear-gradient(to right,  #0075fd 0%,#0075fd ${fileProgress[file.index]}%,#dae2e6 ${fileProgress[file.index]}%,#dae2e6 100%)`, 'height': '7px' }}></div>
                                        <div className="percent">{fileProgress[file.index] > 0 ? fileProgress[file.index] + '%' : ''}</div>
                                    </div>
                                </div>
                                <hr />
                                <div className='px-0'>
                                    <div className='accordion text-dark' onClick={() => { file.index == accordionOpen ? toggleAccordion('') : toggleAccordion(file.index) }}>
                                        Add Categories, Meta Data & Keywords <span>{accordionOpen == file.index ? <i className="fa-solid fa-angle-up dropdown-arrow"></i> : <i className="fa-solid fa-angle-down dropdown-arrow"></i>}</span>
                                    </div>
                                    <div className={`accordion-content ${accordionOpen == file.index ? 'active pt-3' : ''}`}>
                                        <div className={`categories-dropdown-container mb-3 w-100`} id='categories-select' ref={categoriesSelectRef} title='Category of document. Different type of categories can help to segregate documents.' >
                                            <div className='input-field'>
                                                <div className='search-category-container'>
                                                    <input type='text' className={`search-category-name`} placeholder='Select Categrories' id='search-category-input' onFocus={()=>{setShowOptions(file.index)}} value={Object.keys(searchCategoryInput).includes(file.index) ? searchCategoryInput[file.index] : ''} onChange={(e) => { handleSearchCategory(e.target.value);setSearchCategoryInput(prev=>({...prev,[file.index]:e.target.value})) }} />
                                                    <i className="fa-solid fa-chevron-down cursor-pointer" id='dropdown-arrow' onClick={() => { handleDropdown(file.index);clearSearchFilter() }}></i>
                                                </div>
                                                <div className='selected-categories-container'>
                                                    {selectedCategories[file.index] && selectedCategories[file.index].map(category => {
                                                        return <span className='selected-category py-1'>{category.name} <i className="fa-solid fa-xmark px-1 small" onClick={() => { removeSelectedCategory(file.index, category.id) }}></i></span>
                                                    })}
                                                </div>
                                            </div>
                                            <div className={`options-container ${showOptions == file.index ? '' : 'd-none'}`} ref={optionsRef} >
                                                {categoriesOptionsArray.length > 0 ? categoriesOptionsArray.map(category => { return <li className={`category-option ${selectedCategories[file.index] && selectedCategories[file.index].find(el => el.id == category.id) ? 'selected' : ''}`} onClick={() => { handleCategoryOptionSelect(file.index, category) }}>{category.name}</li> }) :
                                                    <div className={``}>No Options Available</div>
                                                }
                                            </div>
                                        </div>
                                        <label htmlFor='metadata'>Meta Data:<span className='info-icon' title='Metadata of document. This will help the customer to search document for sync process.'><i class="fas fa-info-circle small"></i></span><span className={`validation-error`}>{Object.keys(errorsMetadataKeywords).includes(file.index) ? errorsMetadataKeywords[file.index].metadata : ''}</span></label>
                                        <textarea name="metadata" id='metadata' className='metadata-input mb-2 form-control' value={Object.keys(fileExtraData).includes(file.index) ? fileExtraData[file.index].metadata : ''} placeholder='Enter metadata' onChange={(e) => { handleDataChange(e, file.index) }} ></textarea>
                                        <label htmlFor='keyword'>Keywords:<span className='info-icon' title='Keywords of documents. This will help the customer to search documents for sync process.'><i class="fas fa-info-circle small"></i></span><span className={`validation-error`}>{Object.keys(errorsMetadataKeywords).includes(file.index) ? errorsMetadataKeywords[file.index].keywords : ''}</span></label>
                                        <textarea name="keywords" id='keyword' className='form-control' value={Object.keys(fileExtraData).includes(file.index) ? fileExtraData[file.index].keywords : ''} onChange={(e) => { handleDataChange(e, file.index) }} placeholder='Enter Keywords'></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    })}
                </section>
                <div>
                    <button className='btn btn-primary ingest-button mt-2' onClick={() => { uploadFiles() }} disabled={ingestDisabled}>Ingest</button>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        logout,
        verifyUser
    }, dispatch);
}

const mapStateToProps = (state) => {
    return {
        users: state.users,
        categoriesOptions: state.categories.categoriesOptions,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentIngestion)