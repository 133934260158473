import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { removeToast } from '../../../Redux/Actions/action.toast';

import './toast.css';

function Toast({toastList,removeToast}) {
    const [toasts,setToasts] = useState([]);
    useEffect(() => {
        setToasts(toastList);
    }, [toastList])
    return (
        <div className={`alert-container ${toasts.length?'':'d-none'}`} >
        { toasts && toasts.map((toast)=>{
           return <div className={`alert ${toast.toastType} mb-1 flex-wrap h-auto`} key={toast.id}>
                <span>{toast.icon}</span>
                <span className='alert-type mx-2'>{toast.toastType}! </span> <span>{toast.message}</span>
                <span className='close-alert-button'><i className="fa-solid fa-xmark close-alert-button" onClick={() => {removeToast(toast.id); }}></i></span>
            </div>
        })}   
            </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        removeToast
    }, dispatch);
}

const mapStateToProps = (state) => {
    return {
        toastList: state.toasts.toasts,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Toast)