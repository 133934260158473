import React, { useState,useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigate } from 'react-router-dom';
import { addNewCategory, editExistingCategory, getCategories } from '../../Redux/Actions/action.category';
import { getAllCategorieswithPagination } from '../../Redux/Actions/action.category';
import { signOut } from 'firebase/auth';
import { auth } from '../firebaseConfig';

function AddCategory({toast, editCategory, closeModal, actionType, getCategories, addNewCategory, editExistingCategory,getAllCategorieswithPagination,pageInfo}) {
  const navigate = useNavigate(); 
  const [categoryDetails,setCategoryDetails] = useState(editCategory);
  const [validationError,setValidationError] = useState({
    name:'',
    description:''
  })

  useEffect(()=>{
    if(editCategory){
      setCategoryDetails(editCategory)
    }else{
      setCategoryDetails({
        id:'',
        name:'',
        description:''
      })
    }
    setValidationError({
      name:'',
      description:''
    })
  },[editCategory])
  

  const handleInputChange = (e)=>{
    const {name,value} = e.target;
    setCategoryDetails(prev=>({
      ...prev,
      [name]:value
    }));
  }

  const getCategoriesListing = () => {
    const params = {
      page:1,
      perPageSize:100,
      sortColumn:pageInfo.sortColumn,
      sortType:pageInfo.sortType,
      searchString:''
    }
    getAllCategorieswithPagination(params).then(res=>{
      console.log(res)
    }).catch(error=>{
      console.log(error)
    })
  }
  const handleSubmit= async()=>{
    const isValid = await handleValidation();
    if(!isValid) return;
    const category = {
      name:categoryDetails.name.trim(),
      description: categoryDetails.description.trim()
    }

    // ---- EDIT AN EXISTING CATEGORY ------
    if(actionType == 'Edit'){
      category.id = categoryDetails.id;
      editExistingCategory(category).then(res=>{
        if(res && res.status=='logout'){
          signOut(auth).then(res=>{
            navigate('/');
          }).catch(error=>console.log(error))
          return
        }
        if(res && res.status !=='logout'){
          toast(res.status,res.message);
          if(res.status=='success'){
            getCategoriesListing();
            getCategories();
          }
          closeModal();
          setCategoryDetails({
            id:'',
            name:'',
            description:''
          });
        }
      }).catch(error=>{console.log(error)});
    }

    // ---- ADD A NEW CATEGORY ----------
    if(actionType =='Add'){
      addNewCategory(category).then(res=>{
        if(res && res.status=='logout'){
          signOut(auth).then(res=>{
            navigate('/');
          }).catch(error=>console.log(error))
          return
        }
        toast(res.status,res.message);
        if(res.status=='success'){
          getCategoriesListing();
          getCategories();
        }
        closeModal();
        setCategoryDetails({
          id:'',
          name:'',
          description:''
        });
      }).catch(error=>{console.log(error)});
    }
    clearValidation();
  }

  const clearValidation = () => {
    setValidationError({
      name:'',
      description:''
    })
  }

  const handleValidation = () => {
    let {name,description} = categoryDetails;
    const regex = /[^a-zA-Z0-9, ]/g;
    name=name.trim();
    description = description.trim();
    let error;
    if(!name || !description){
      error = {
        name: !name?'Please enter a valid name':'',
        description: !description? 'Please enter valid description':''
      }
    }
    if(name && regex.test(name)){
      error={
        name: 'Special Characters are not allowed in name.',
        description: error?.description?error.description:''
      }
    }else{
      if(name && name.length>100){
        error={
          name: 'Number of characters in category name should not be more than 100.',
          description: error?.description?error.description:''
        }
      }
      const regexAlphabets = /[a-zA-Z ]/;
      if(name && !regexAlphabets.test(name[0])){
        error={
          name: 'Category name cannot start with a number.',
          description: error?.description?error.description:''
        }
      }
      if(name && !regexAlphabets.test(name)){
        error={
          name: 'Only Numbers are not allowed in category name.',
          description: error?.description?error.description:''
        }
      }
    }
    if(error){
      setValidationError(error);
      return false;
    }else{
      return true;
    }
  }

  const handleCancel = () => {
    setCategoryDetails({
      id:'',
      name:'',
      description:''
    });
    closeModal();
  }
  return (
    <div className="d-flex flex-column align-items-start pt-3">
      <label className='keywords-input-label' htmlFor='Name'>Name:<span className={`validation-error`}>{validationError.name}</span></label>
      <input type="text" name="name" id='Name' className='mb-3 form-control' placeholder='Enter Name' onChange={(e)=>{handleInputChange(e)}} value={categoryDetails.name} />
      <label className='keywords-input-label' htmlFor='Description'>Description:<span className={`validation-error`}>{validationError.description}</span></label>
      <textarea name="description" id='Description' className='form-control mb-3' placeholder='Enter Description' onChange={(e)=>{handleInputChange(e)}} value={categoryDetails.description} ></textarea>
      <div>
        <button className='btn btn-primary ingest-button mt-2 w-auto px-3' onClick={()=>handleSubmit()}>Save</button>
        <button className='btn btn-dark ingest-button mt-2 w-auto mx-2' onClick={()=>{handleCancel()}}>Cancel</button>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    pageInfo: state.categories.pageInfo
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getCategories,
    addNewCategory,
    editExistingCategory,
    getAllCategorieswithPagination,
  },dispatch);
}

export default connect(mapStateToProps,mapDispatchToProps)(AddCategory)