import axios from "axios";
import { USER_LOGIN, USER_REGISTRATION, ADD_LOADING, REMOVE_LOADING, USER_LOGOUT } from "./index";
// import { USER_REGISTRATION } from "./index";

const BASE_API_URL = process.env.REACT_APP_API_BASE_URL
export const loginUser = (userDetails) => async (dispatch) => {
    let response;
    setLoading(dispatch, 'userLogin', true);
    const { email, password } = userDetails;
    const url = `${BASE_API_URL}/db/auth/login`;

    let data = {
        username: email,
        password: password,
    };
    let config = {
        credentials: "include",
        method: "POST",
        url: url,
        headers: {
            "access-control-allow-origin": "*",
            "Content-type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Methods": "*",
        },
        data: data,
        crossDomain: true,
    };

    await axios(config).then((res) => {
        if (res.status == 200) {
            if (res.data.statusCode == '200') {
                response = {
                    status: 'success',
                    message: 'Login Successful',
                    data : res.data
                }
                localStorage.setItem('user', JSON.stringify(res.data));
                dispatch({
                    type: USER_LOGIN,
                    payload: res.data
                })
            }
            setLoading(dispatch, 'userLogin', false);
        }
    }
    ).catch(error => {
        if(error && error?.response?.data?.status==401){
            logout();
            return;
        }
        if (error && error?.response?.data.status == '400' || error.status == '404') {
            response = {
                status: 'error',
                message: error.messages[0],
            }
            return
        }
        if(error){
            response = {
                status: 'error',
                message: 'Login Error',
            }
        }
    });
    return response;
}

export const verifyUser = (accessToken) => async(dispatch)=> {
    const url = `${BASE_API_URL}/db/auth/isTokenValid`
    const config = {
        url:url,
        method:'POST',
        headers:{
            "access-control-allow-origin": "*",
            "Content-type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Methods": "*",
            "Authorization": `Bearer ${accessToken}`
        }
    }
    return axios(config);
}


export const logout = (accessToken) => async(dispatch) => {
    const accessToken = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).user.stsTokenManager.accessToken : '';
    const url = `${BASE_API_URL}/db/auth/logout`;

    if(!accessToken){
        return
    }
    let response;

    const config = {
        method: "POST",
        url: url,
        headers: {
            "access-control-allow-origin": "*",
            "Content-type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Methods": "*",
            "Authorization": `Bearer ${accessToken}`
        },
        crossDomain: true,
    }

    await axios(config).then(res=>{
         response = {
            status:'success',
            message:'Logout Successful'
        }
    }).catch(error=>{
        console.log(error);
         response = {
            status:'error',
            message:'Invalid Token'
        }
    });
    return response;
}

export const setLoader = (apiName,loadingState) => (dispatch) => {
    setLoading(dispatch,apiName,loadingState)
}

const setLoading = (dispatch, apiName, loadingState) => {
    loadingState ?
        dispatch({
            type: ADD_LOADING,
            payload: apiName
        }) :
        dispatch({
            type: REMOVE_LOADING,
            payload: apiName
        });
    }

