import { combineReducers } from "redux";
import { userReducer } from "./Reducers/userReducer"; 
import { loadingState } from "./Reducers/loadingReducer";
import { documents } from "./Reducers/documentsReducer";
import { toastReducer } from "./Reducers/toastReducer";
import { customer } from "./Reducers/customerReducer";
import { categories } from "./Reducers/categoriesReducer";

export const rootReducer = combineReducers({
    users: userReducer,
    loading: loadingState,
    documents: documents,
    toasts: toastReducer,
    customers: customer,
    categories: categories
});