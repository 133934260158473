import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, Redirect, Navigate } from 'react-router-dom';
import './App.css';
import Dashboard from './components/Dashboard/dashboard';
import ProtectedRoute from './components/ProtectedRoute';
import Login from './components/User/Login/Login';



function App({ loading }) {
  return (
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route exact path='/dashboard' element={<ProtectedRoute Component={Dashboard} />} />
            <Route exact path='/' element={<ProtectedRoute Component={Login} />} />
          </Routes>
        </BrowserRouter>
      </div>
  );
}


export default App;