import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// const data = [
//   {
//     id:'1',
//     name:'Dare Rudgard',
//     key:'WDGQX-WC2Y3-4R966-TK3H3-HXRB8',
//     status:'enabled',
//     categories:'Agriculture, Art & Culture'
//   },
//   {
//     id:'2',
//     name:'Padriac Chaundy',
//     key:'F6HQW-Q3799-9CJXR-9P3YD-6CJJ6',
//     status:'enabled',
//     categories:'Agriculture'
//   },
//   {
//     id:'3',
//     name:'Julie Cotgrave',
//     key:'DPDQK-H94J9-TWMTR-K2K69-FRKHM',
//     status:'enabled',
//     categories:'Art & Culture'
//   },
//   {
//     id:'4',
//     name:'Nanice Capoun',
//     key:'PXV3X-T8MP7-D3XFW-2327C-VPDQ8',
//     status:'enabled',
//     categories:'Art & Culture, Communications, Information Technology'
//   },
//   {
//     id:'5',
//     name:'Amby Puckey',
//     key:'BBY46-G888F-PKWW6-X4CVD-CGF43',
//     status:'enabled',
//     categories:'Information Technology'
//   },
//   {
//     id:'6',
//     name:'Odelle Dangerfield',
//     key:'J2C8K-83GRM-269TM-RHW43-P7CDQ',
//     status:'enabled',
//     categories:'Space, Agriculture, Art & Culture'
//   },
//   {
//     id:'7',
//     name:'Zarla Tollet',
//     key:'JMD9T-8C93Q-MDPKT-X9HJX-B64RJ',
//     status:'enabled',
//     categories:'Agriculture, Finance'
//   },
//   {
//     id:'8',
//     name:'Timotheus Fitzpatrick',
//     key:'FCKGW-RHQZ7-YXRQW-7AS6W-3C8Q8',
//     status:'enabled',
//     categories:'Finance, Agriculture, Art & Culture'
//   },
//   {
//     id:'9',
//     name:'Dare Rudgard',
//     key:'WDGQX-WC2Y3-4R966-TK3H3-HXRB8',
//     status:'enabled',
//     categories:'Agriculture, Art & Culture'
//   },
//   {
//     id:'10',
//     name:'Padriac Chaundy',
//     key:'F6HQW-Q3799-9CJXR-9P3YD-6CJJ6',
//     status:'enabled',
//     categories:'Agriculture'
//   },
//   {
//     id:'11',
//     name:'Julie Cotgrave',
//     key:'DPDQK-H94J9-TWMTR-K2K69-FRKHM',
//     status:'enabled',
//     categories:'Art & Culture'
//   },
//   {
//     id:'12',
//     name:'Nanice Capoun',
//     key:'PXV3X-T8MP7-D3XFW-2327C-VPDQ8',
//     status:'enabled',
//     categories:'Art & Culture, Communications, Information Technology'
//   },
//   {
//     id:'13',
//     name:'Amby Puckey',
//     key:'BBY46-G888F-PKWW6-X4CVD-CGF43',
//     status:'enabled',
//     categories:'Information Technology'
//   },
//   {
//     id:'14',
//     name:'Odelle Dangerfield',
//     key:'J2C8K-83GRM-269TM-RHW43-P7CDQ',
//     status:'enabled',
//     categories:'Space, Agriculture, Art & Culture'
//   },
//   {
//     id:'15',
//     name:'Zarla Tollet',
//     key:'JMD9T-8C93Q-MDPKT-X9HJX-B64RJ',
//     status:'enabled',
//     categories:'Agriculture, Finance'
//   },
//   {
//     id:'16',
//     name:'Timotheus Fitzpatrick',
//     key:'FCKGW-RHQZ7-YXRQW-7AS6W-3C8Q8',
//     status:'enabled',
//     categories:'Finance, Agriculture, Art & Culture'
//   },
// ]

function CustomerManagementTable({customerList,categoriesOptions,toggleModal}) {
  const [customerData,setCustomerData] = useState();
  useEffect(()=>{
    const data = customerList.length>0 && customerList.map(customer=>{
      return {
        ...customer,
        configuredCategories: customer.configuredCategories.map(el=>categoriesOptions.filter(category=>category.id==el)[0])
      }
    });
    setCustomerData(data)
  },[customerList])
  return (
    <div className='document-table w-100'>
      <div className='table-section'>
          <table className='table-properties table-bordered'>
            <thead>
            <tr className='table-headers'>
                  <th className='py-3 px-2'>Customer Name</th>
                  <th className='py-3 px-2'>Key</th>
                  <th className='py-3 px-2'>Status</th>
                  <th className='py-3 px-2'>Configure Categories</th>
              </tr>
            </thead>
            <tbody>
              {customerData && customerData?.length && customerData.map(customer=>{
              return <tr key={customer.id} className='table-body-data'>
                    <td className='py-2 px-2 min-w-150'>{customer.name}</td>
                    <td className='py-2 px-2'>{customer.key}</td>
                    <td className='py-2 px-2'>{customer.status}</td>
                    <td className='py-2 px-2'>
                      {customer.configuredCategories.map(category=>category?.name).join(', ')}
                    </td>
                    <td className='py-2 px-2 text-center'>
                      <span onClick={()=>{toggleModal('Edit',customer)}}><img src='./images/pencil-alt-solid.svg' className='edit' alt='edit' /></span>
                    </td>
              </tr>
              })}
            </tbody>
          </table>
      </div>
    </div>
  )
}
const mapStateToProps = (state)=>{
  return {
    customerList: state.customers.customerList,
    categoriesOptions: state.categories.categoriesOptions
  }
}

const mapDispatchToProps = (dispatch)=>{
  return bindActionCreators({

  },dispatch)

}
export default connect(mapStateToProps,mapDispatchToProps)( CustomerManagementTable)