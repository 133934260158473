import React, { useState,useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import Loader from './global/Loader/Loader';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { addToast, removeToast } from './../Redux/Actions/action.toast';
import Toast from './global/Toast/toast';
import Login from './User/Login/Login';
import { verifyUser } from '../Redux/Actions/action.users';
import { getAuth, onAuthStateChanged } from "firebase/auth";

const icons = {
  'success': <i className="fa-solid fa-square-check alert-icon"></i>,
  'error': <i className="fa-solid fa-circle-exclamation alert-icon"></i>,
  'info': <i className="fa-solid fa-circle-info alert-icon"></i>,
  'warning': <i className="fa-solid fa-triangle-exclamation alert-icon"></i>
}


function ProtectedRoute({Component,addToast,removeToast,loading, verifyUser}) {
    const [isLoading,setLoading] = useState(false);
    const navigate = useNavigate();

    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user && !user.stsTokenManager.isExpired) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/auth.user
      const uid = user.uid;
      // navigate('dashboard')
      } else {
      // User is signed out
      // navigate('/');
      localStorage.removeItem('user');
      }
    });

// -------------------- User Token Verification --------------------------

    useEffect(()=>{
      const isLoggedIn = localStorage.getItem('user');
          if(!isLoggedIn && Component !== Login){
              navigate('/');
          }
      },[]);


// -------------- Show Loading ------------------------------------------

    useEffect(()=>{
      if(loading.loading.length>0){ setLoading(true); return; };
      setLoading(false)
    },[loading])


// -------------- Toast functions --------------------------------------

    const addNewToast = (toastType,message) => {
      const newToast = {
        id: Date.now(),
        message: message,
        toastType: toastType,
        icon: icons[toastType]
    }
      addToast(newToast);

      setTimeout(()=>{
        removeToast(newToast.id);
      },5000)
    }

    const closeToast = (toastId) => {
      removeToast(toastId);
    }
  return (
  <>
    {isLoading && <Loader/>}
    <Toast />
    <Component toast={addNewToast} closeToast={closeToast} />
  </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    addToast,
    removeToast,
    // verifyUser
  }, dispatch);
}

const mapStateToProps = (state) => {
  return {
      loading:state.loading
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute)