import { SET_CUSTOMER_LIST } from '../Actions'

const initialState = {
    customerList:[],
    pageInfo:{}
}

export const customer = (state=initialState,action)=>{
    switch(action.type){
        case SET_CUSTOMER_LIST:
            return {
                ...state,
                customerList:action.payload.customerList,
                pageInfo: action.payload.pageInfo
            };
        default: return state
    }
}